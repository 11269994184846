import {
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import googlemap from '../images/googlemap.jpeg'
import SecondImage from '../images/newcontact.jpg'
import NewHeader from '../components/NewHeader'
import Firebase from '../components/Firebase'
const useStyles = makeStyles(theme => ({
  whiteColor: {
    color: '#FFFFFF',
  },
  link: {
    textDecoration: 'none',
    color: '#FFFFFF',
  },
  colorWhite: {
    color: '#FFFFFF',
  },
  marginTop84: {
    marginTop: -120,
  },
  padding24: {
    padding: 24,
  },
  marginTop24: {
    marginTop: 14,
  },
  shiv: {
    height: '60vh',
    backgroundImage: 'url(' + SecondImage + ')',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },

  centerTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '50vh',
    color: '#FFF',
    fontSize: 40,
  },
}))

const ContactUs = () => {
  const classes = useStyles()
  const [snack, setSnack] = useState(false)
  const [msg, setMsg] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [comment, setComment] = useState('')

  useEffect(() => {
    function signInSiteUser() {
      Firebase.auth()
        .signInWithEmailAndPassword('siteuser@admin.com', 'password')
        .then(function(resp) {})
        .catch(function(err) {})
    }
    signInSiteUser()
  }, [])

  function closeSnack() {
    setSnack(false)
  }

  function handleContactus(event) {
    event.preventDefault()
    console.log(email + ' : ' + name + ' ' + comment)
    let d = {
      email: email,
      name: name,
      msg: comment,
      timestamp: Date.now(),
    }
    Firebase.firestore()
      .collection('site_messages')
      .doc(Date.now() + '_' + email)
      .set(d)
      .then(function() {
        console.log('Success')
      })
      .catch(function(error) {
        console.log(error.message)
      })
  }
  return (
    <Layout>
      <SEO title="Contact Us" />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={snack}
        autoHideDuration={6000}
        onClose={closeSnack}
        message={msg}
      />
      <NewHeader title="Contact Us" image={SecondImage} />

      <div className={classes.marginTop84}>
        <Container>
          <Grid
            container
            spacing={2}
            alignItems="center"
            direction="row"
            justify="center"
          >
            <Grid item md={4} xs={12}>
              <Paper square className={classes.padding24}>
                <Typography variant="h6">Feel free to contact us</Typography>
                <form style={{ marginTop: 8 }} onSubmit={handleContactus}>
                  <TextField
                    className={classes.marginTop24}
                    fullWidth
                    id="outlined-basic"
                    label="Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.marginTop24}
                    fullWidth
                    id="outlined-basic"
                    label="Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    variant="outlined"
                  />
                  <TextField
                    fullWidth
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                    className={classes.marginTop24}
                    id="outlined-multiline-static"
                    label="Message"
                    multiline
                    rows="4"
                    variant="outlined"
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.marginTop24}
                  >
                    Send
                  </Button>
                </form>
              </Paper>
            </Grid>
            <Grid item md={8} xs={12}>
              <Paper>
                <img src={googlemap} fullWidth alt="Google Map" />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Layout>
  )
}
export default ContactUs
